@import 'icosa_theme/variables';
@import 'application';

@import 'icosa_theme/old_about';

@import 'icosa_theme/boost';
@import 'icosa_theme/aboutpage_fixes';

body.app-body {
    background: url('../images/background-photo.jpg') !important;
    background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
}

.ui {
    background: none !important;
}

div.emoji-mart-anchors {
    color: $ui-base-color;
}

/* xkeeper-tweaks */
textarea:fo­cus-ring, input:fo­cus-ring, button:fo­cus-ring, *:fo­cus-ring {
    /* Make the textbox and buttons indicate being highlighted in some way */
    box-shadow: inset 0 0 2px 1px darken($ui-base-color, 20%);
}

.compose-form__buttons button.active {
    /* make the cw button more obvious when toggled */
    background: rgba(0, 0, 0, .6);
    border-radius: 5px;
    color: lighten($classic-secondary-color, 26%);
    box-shadow: inset 0px 2px 3px 1px rgba(0, 0, 0, .6);
}

/* aeon-tweaks */
.community-timeline__section-headline,
.public-timeline__section-headline,
.account__section-headline {
    border-bottom: 0;
    background: #4a4b51;
}

.community-timeline__section-headline a,
.public-timeline__section-headline a,
.account__section-headline a {
	padding: 2px 0 2px 0;
    background: #4a4b51;
    border-bottom: 2px solid #53555b;
    border-top: 2px solid #4a4b51
}

.community-timeline__section-headline .active,
.public-timeline__section-headline .active,
.account__section-headline .active {
	background: #404146;
    border-bottom: 2px solid #404146;
    border-top: 2px solid #53555b;
	border-radius: 5px 5px 0 0;        
}

.community-timeline__section-headline .active::before,
.public-timeline__section-headline .active::before,
.account__section-headline .active::before {
	display: none !important;
    visibility: hidden !important;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
}

.community-timeline__section-headline .active::after,
.public-timeline__section-headline .active::after,
.account__section-headline .active::after {
    display: none !important;
    visibility: hidden !important;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
}

.announcements__item {
    background:url('https://icosahedron.website/icosan_announce.png') !important;
    background-size:170px !important;
    background-repeat:no-repeat !important;
    background-position-x: -40px !important;
    padding-left:100px !important;
    padding-right:10px !important;
}

.announcements__item__range {
    margin-top: 25px !important;
    margin-left: 30px !important;
}

.announcements__mastodon {
    display:none !important;
}

.announcements__container {
    width: calc(100%) !important;
}

div.status, div.notification__message {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
}

div.status__action-bar {
    margin-top: 10px !important;
}

/*
div.status__info {
    margin-bottom: 2px !important;
    gap: 2px !important;
}

div.reply-indicator__content p, div.status__content p {
    margin-bottom: 7px !important;
}
*/

.status .attachment-list, .status .audio-player, .status .media-gallery, .status .video-player {
    margin-top: 5px !important;
}

svg.logo {
    background:url('../images/logo_text.png');
    background-size:contain;
    background-repeat: no-repeat;
    width: 100px !important;
    height: 25px !important;
    overflow: hidden !important;
}

div.account__relationship button.active {
    color: #a5d296;
}

div.status__avatar {
   margin-right: 10px !important;
}

a.status__display-name {
   gap: 0 !important;
}

div.navigation-bar div.account__avatar {
   margin-right: 10px !important;
}

div.navigation-bar {
   gap: 0px !important;
}

div.ui__header__links div.account__avatar {
   margin-left: 10px !important;
}

div.ui__header__links {
   gap: 0px !important;
}

/* 4.3 fixes */
div.column h1 {
   background:var(--background-color);
}

div.column .scrollable {
   background:var(--background-color);
}

div.notification__filter-bar {
    background:var(--background-color);
}

div.notification-ungrouped__header {
    margin-bottom: 0px;
}

div.notification-ungrouped {
    padding: 10px 0px;
}

.content-warning::after, .content-warning::before {
    background: var(--background-border-color);
}

div.status__content p {
    margin-bottom: 7px !important;
}

button.status__content__translate-button {
    padding:0;
}

div.column-header__collapsible {
    background:var(--background-color);    
}

div.content {
    background:var(--background-color);
}

/** from cpsdqs compact theme **/
/* compact content warnings */
.content-warning {
  margin-bottom: 0.5rem;
  padding: 0.25rem 0.75rem;

  /* actually just delete the whole content warning box */
  margin-bottom: 0.25rem;
  padding: 0;
  border: none;
  background: none;
}
.status .content-warning {
  margin-bottom: 0.25rem;
}
.detailed-status .content-warning {
  margin-bottom: 0.5rem;
}
.content-warning::before,
.content-warning::after {
  display: none;
}

.content-warning > :nth-last-child(2) {
  /* the last <p>, probably */
  margin-bottom: 0;
}

.content-warning:not(:has(:nth-child(3))) > p {
  /* this CW only has a <p> and the show more button */
  display: inline;
  margin-right: 0.5rem;
}

.content-warning .link-button {
  display: inline-grid;
  font-size: 0.92em;
  text-transform: uppercase;
  background: color-mix(in lch, var(--background-color), currentColor 20%);
  box-shadow: inset 0 0 0 1px color-mix(in lch, var(--background-color), currentColor 30%);
  padding: 0.125rem 0.5rem;
  border-radius: 0.25rem;
}
.content-warning .link-button:hover {
  background: color-mix(in lch, var(--background-color), currentColor 35%);
  text-decoration: none;
}

.content-warning .link-button::before,
.content-warning .link-button::after {
  grid-area: 1 / 1;
  text-align: center;
  content: 'Show More';
}
.content-warning .link-button::after {
  /* used only for ensuring consistent width when text changes */
  visibility: hidden;
}
.status:has(.status__content) .content-warning .link-button::before,
.notification-group__embedded-status:has(.notification-group__embedded-status__content) .content-warning .link-button::before {
  content: 'Show Less';
}
.content-warning .link-button > span {
  display: none;
}

/* compact hashtag & action bar */
.status .hashtag-bar {
  margin-top: 0.5rem;
}
.status .status__action-bar {
  /* icosahedron.website !important */
  margin-top: 0.5rem !important;
}
.status .status__content + .hashtag-bar {
  margin-top: 0.25rem;
}
.status .status__content + .status__action-bar {
  /* icosahedron.website !important */
  margin-top: 0.25rem !important;
}
.status__action-bar .icon,
.detailed-status__action-bar .icon {
  width: 1.25rem;
  height: 1.25rem;
}
.detailed-status__meta {
  margin-top: 0.5rem;
}
.detailed-status__action-bar {
  padding: 0.5rem 0;
}

/* notification filters */
.account__section-headline a,
.account__section-headline button,
.notification__filter-bar a,
.notification__filter-bar button {
  padding: 0.5rem 0;
}
.notification__filter-bar .icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* compact dropdowns */
.dropdown-menu__item a, .dropdown-menu__item button {
  padding: 0.25rem 0.5rem;
}

/* compact compose form */
.compose-form .reply-indicator {
  grid-template-columns: 2.25rem minmax(0, 1fr);
  grid-template-rows: 2.25rem max-content;
}
.layout-multiple-columns .compose-form {
  padding: 0.5rem;
  gap: 1rem;
}
div.navigation-bar div.account__avatar {
  /* icosahedron.website fix */
  margin-right: 0 !important;
}

/* thinner cols */
div.column {
  width: 380px;
}

/* logo fix */
div.more-from-author svg.logo {
    display:none;
}
