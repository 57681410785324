// Commonly used web colors
$black: #000000;            // Black
$white: #ffffff;            // White
$success-green: #79bd9a !default;    // Padua
$error-red: #df405a !default;        // Cerise
$warning-red: #ff5050 !default;      // Sunset Orange
$gold-star: #ca8f04 !default;        // Dark Goldenrod

$red-bookmark: $warning-red;

// Values from the classic Mastodon UI
$classic-base-color: #404146;         // Midnight Express
$classic-primary-color: #a5d296;      // Echo Blue
$classic-secondary-color: #a5d296;    // Pattens Blue
$classic-highlight-color: #8283a7;    // Summer Sky

// Variables for defaults in UI
$base-shadow-color: $black !default;
$base-overlay-background: $black !default;
$base-border-color: $white !default;
$simple-background-color: $white !default;
$primary-text-color: $white !default;
$valid-value-color: $success-green !default;
$error-value-color: $error-red !default;

// Tell UI to use selected colors
$ui-base-color: $classic-base-color !default;             // Darkest
$ui-base-lighter-color: lighten($ui-base-color, 26%) !default;
$ui-primary-color: $classic-primary-color !default;       // Lighter
$ui-secondary-color: $classic-secondary-color !default;   // Lightest
$ui-highlight-color: $classic-highlight-color !default;   // Vibrant

// Variables for texts
$primary-text-color: $white !default;
$darker-text-color: $ui-primary-color !default;
$dark-text-color: $ui-base-lighter-color !default;
$secondary-text-color: $ui-secondary-color !default;
$highlight-text-color: $ui-highlight-color !default;
$action-button-color: $ui-base-lighter-color !default;
$passive-text-color: $gold-star !default;
$active-passive-text-color: $success-green !default;

// For texts on inverted backgrounds
$inverted-text-color: $ui-base-color !default;
$lighter-text-color: $ui-base-lighter-color !default;
$light-text-color: $ui-primary-color !default;

// Language codes that uses CJK fonts
$cjk-langs: ja, ko, zh-CN, zh-HK, zh-TW;

// Variables for components
$media-modal-media-max-width: 100%;

// put margins on top and bottom of image to avoid the screen covered by image.
$media-modal-media-max-height: 80%;
